<template>
  <div class="visit-room">
    <v-progress-linear v-if="loading" color="primary" indeterminate />
    <div v-if="encounter && !encounterIsCancelled && !loading">
      <div v-if="!isFullScreen" class="visit-room-header">
        <!--        <v-chip v-if="roomIsStarted && !roomIsFinished && timer" color="red darken-1" dark label>-->
        <!--          {{ timer }}-->
        <!--        </v-chip>-->
        <span class="visit-room-header-select">{{ $t("visits.visitRoomWith") }} {{ encounter.practitioner.name }}</span>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-calendar-blank</v-icon>
          <p>{{ dateFormated }}</p>
        </div>
        <div class="visit-room-header-bordered">
          <v-icon color="icon">mdi-clock-outline</v-icon>
          <p>{{ timeRange }}</p>
        </div>
      </div>

      <v-row :class="{ fullscreen: isFullScreen }" class="visit-room-content" no-gutters>
        <v-col sm="12">
          <VideoRoom v-if="!roomIsFinished && room && encounter.virtualService !== 'callback'" :call-time="timer" />
          <div v-if="!roomIsFinished && room && encounter.virtualService === 'callback'">
            <p class="callback">Phone Call consult</p>
          </div>
          <PatientVisitSummaryPage
            v-if="(roomIsFinished && room) || encounter.status === 'triaged'"
            :encounter="encounter"
            class="visit-room-content-summary"
          />
        </v-col>
      </v-row>
    </div>
    <ExpiredEncounter v-if="encounterIsCancelled" />
  </div>
</template>
<script>
import { mapState } from "pinia";

import VideoRoom from "@/components/VideoRoom";
import ExpiredEncounter from "@/components/VisitSummary/ExpiredEncounter.vue";
import { visitRoomMixin } from "@/mixins/visitRoom";
import { useEncountersStore } from "@/pinia-store/encounters";
import { useVideoRoomStore } from "@/pinia-store/videoRoom";
import { EncounterStatusEnum } from "@/types/EncounterStatusEnum";
import PatientVisitSummaryPage from "@/views/Patient/Visits/Summary";

export default {
  components: {
    ExpiredEncounter,
    VideoRoom,
    PatientVisitSummaryPage,
  },
  mixins: [visitRoomMixin],
  computed: {
    ...mapState(useEncountersStore, ["currentEncounter"]),
    ...mapState(useVideoRoomStore, ["isFullScreen"]),
    encounterIsCancelled() {
      return this.currentEncounter?.status === EncounterStatusEnum.cancelled;
    },
  },
};
</script>

<style lang="scss" scoped>
.visit-room-content-summary {
  height: 100%;
}

.callback {
  text-align: center;
  font-size: 36px;
  align-self: center;
  padding-top: 40px;
}

.visit-room-content {
  height: calc(100vh - $video-call-header-height - var(--footer-height));
  min-height: 240px;

  &:fullscreen,
  &.fullscreen {
    height: 100%;
  }
}
</style>
